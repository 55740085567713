<template>
  <div class="page">
    <div class="section padding20 d-flex flex-column">
      <!-- Transaction 交易 -->
      <div class="flex-1" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.5)">
        <!-- <h1>Transaction</h1> -->
        <el-timeline v-if="activityList.length" class="timeline-account">
          <el-timeline-item v-for="(item, i) in activityList" :key="i" :timestamp="item.created_at" placement="top">
            <div class="box flex align-center">
              <img v-if="
                  item.type == 1 ||
                  item.type == 2 ||
                  item.type == 7 ||
                  item.type == 8 || item.type == 60
                " src="../assets/account/mdslogo.png" style="width: 35px; height: 35px" />
              <img v-else-if="
                  item.type == 14 ||
                  item.type == 15 ||
                  item.type == 16 ||
                  item.type == 17
                " src="../assets/account/brclogo.png" style="width: 35px; height: 35px" />
              <img v-else-if="
                  item.type == 50 ||
                  item.type == 51 ||
                  item.type == 52 ||
                  item.type == 53 ||
                  item.type == 54 ||
                  item.type == 55
                " src="../assets/login/logo.webp" style="width: 35px; height: 35px;border-radius: 10px;" />
              <img src="../assets/card/tianshi.png" v-else-if="item.type == 3" width="35px" />
              <img src="../assets/card/shouzu.png" v-else-if="item.type == 4" width="35px" />
              <img src="../assets/summon/sum_icon.png" width="35px" v-else />
              <div class="padding-row10">
                <p v-html="$t('activity.tip.' + item.type, {...item})"></p>
                <!-- https:testnet.bscscan.com -->
                <a :href="process+'/tx/' + item.txhash" target="_blank" rel="noopener noreferrer">{{ item.txhash }}</a>
              </div>
            </div>
          </el-timeline-item>
        </el-timeline>
        <div v-else-if="!loading" class="d-flex ai-center jc-center" style="min-height: 500px">
          <img alt="norecord" src="../assets/public/norecord.png" />
        </div>
        <p v-if="meta.last - meta.current > 0" class="more">
          <span class="c-m hover" @click="pageFun(meta.current + 1)">{{ $t("activity.more") }}
            >></span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import textdata from "../common/dataJaon/text_data.json";
import partner_data1 from "../common/dataJaon/partner_data1.json";
export default {
  data() {
    return {
      loading: false,
      activityList: [],
      process: process.env.VUE_APP_BSC,
      meta: {
        current: 1,
        last: 1,
        total: 0,
      },
    };
  },
  computed: {
    ...mapState({
      sets: (state) => state.sets,
    }),
  },
  mounted() {
    setTimeout(() => {
      this.pageFun(1);
    }, 200);
  },
  methods: {
    addressFilter(str) {
      if (!str) return "--";
      const str1 = str.substring(0, 5);
      const str2 = str.substring(str.length - 4, str.length);
      return str1 + "..." + str2;
    },
    pageFun(page = 1) {
      // console.log(this.$storage.get("playList"));
      // if (this.$storage.get("playList").length <= 0) return false;
      this.loading = true;
      this.$axios
        .get(
          this.$api.getActivityList,
          { page, pageSize: 10 },
          { Serverid: this.$store.state.serverid }
        )
        .then((res) => {
          //  1-充币,   2- 提币, 3-买装备 ,  4-卖装备 ,  5-取消卖装备 ,  6-卖装备成功 ,
          //  7-提币冻结 ,  8-提币驳回 ,  9-激活元龍  , 11-英雄上链  , 12-神器上链,
          //  14-提币brc冻结, 15-提币brc驳回, 16-充币brc, 17-提币brc
          //  22-激活元龍。23-领取元龍 提出卡牌申请-21 提出元龍申请-25
          res.data.list.map((item) => {
            if (item.two_amount) item.two_amount = Math.floor(item.two_amount * Math.pow(10, 6)) / Math.pow(10, 6);
            if (item.amount) item.amount = Math.floor(item.amount * Math.pow(10, 6)) / Math.pow(10, 6);
            if (item.two_address)
              item.two_address = this.addressFilter(item.two_address);
            if (item.assets && item.assets.game_assets_id) {
              if (item.assets.game_assets_id.toString().length > 1) {
                let name = partner_data1.data_partner_base[item.assets.game_assets_id].name;
                item.name =
                  this._i18n.locale == "tw"
                    ? textdata.data_text[name].s_Tw
                    : this._i18n.locale == "en" ? textdata.data_text[name].s_En : textdata.data_text[name].s_Yn;
              } else {
                item.name =
                  this._i18n.locale == "tw"
                    ? textdata.data_text["4" + (item.assets.game_assets_id) + "00001"].s_Tw
                    : textdata.data_text["4" + (item.assets.game_assets_id) + "00001"].s_En;
              }
            }
            // console.log(item.type);
            switch (item.type) {
              case 1:
                item.assets = {
                  type: 0,
                };
                break;
              case 2:
                if (
                  Number(item.amount) <=
                  Number(this.sets.withdraw_fee_min_number)
                ) {
                  item.amount2 = 0;
                }
                if (
                  Number(item.amount) >
                  Number(this.sets.withdraw_fee_min_number)
                ) {
                  if (
                    Number(this.sets.withdraw_fee_min_number) >=
                    Number(item.amount * this.sets.withdraw_fee_ratio)
                  ) {
                    item.amount2 =
                      item.amount - this.sets.withdraw_fee_min_number;
                  } else {
                    item.amount2 = (
                      item.amount *
                      (1 - this.sets.withdraw_fee_ratio)
                    ).toFixed(2);
                  }
                }
                // item.amount2 = item.amount - this.sets.withdraw_fee_min_number;
                item.assets = {
                  type: 0,
                };
                break;
              case 3:
                item.assets = {
                  type: 1,
                };
                break;
              case 4:
                item.assets = {
                  type: 1,
                };
                break;
              case 7:
                if (
                  Number(item.amount) <=
                  Number(this.sets.withdraw_fee_min_number)
                ) {
                  item.amount7 = 0;
                }
                if (
                  Number(item.amount) >
                  Number(this.sets.withdraw_fee_min_number)
                ) {
                  if (
                    Number(this.sets.withdraw_fee_min_number) >=
                    Number(item.amount * this.sets.withdraw_fee_ratio)
                  ) {
                    item.amount7 =
                      item.amount - this.sets.withdraw_fee_min_number;
                  } else {
                    item.amount7 = (
                      item.amount *
                      (1 - this.sets.withdraw_fee_ratio)
                    ).toFixed(2);
                  }
                }
                // item.amount7 = item.amount - this.sets.withdraw_fee_min_number;
                item.assets = {
                  type: 0,
                };
              case 8:
                if (
                  Number(item.amount) <=
                  Number(this.sets.withdraw_fee_min_number)
                ) {
                  item.amount8 = 0;
                }
                if (
                  Number(item.amount) >
                  Number(this.sets.withdraw_fee_min_number)
                ) {
                  if (
                    Number(this.sets.withdraw_fee_min_number) >=
                    Number(item.amount * this.sets.withdraw_fee_ratio)
                  ) {
                    item.amount8 =
                      item.amount - this.sets.withdraw_fee_min_number;
                  } else {
                    item.amount8 = (
                      item.amount *
                      (1 - this.sets.withdraw_fee_ratio)
                    ).toFixed(2);
                  }
                }
                // item.amount8 = item.amount - this.sets.withdraw_fee_min_number;
                item.assets = {
                  type: 0,
                };
                break;
              case 10:
                try {
                  // item.img = state.img["hero" + item.role_id || ""];
                  if (item.assets) item.tokenid = item.assets.tokenid;
                  if (item.user_role)
                    item.nickname = item.user_role.game_nickname;
                  item.nftname = computed(() => t("hero.name." + item.role_id));
                } catch (e) { }
                break;
              case 5:
                if (item.assets.type == 1)
                  // item.heroname = computed(() =>
                  //   t("hero.name." + item.hero_templ_id)
                  // );
                  break;
              case 6:
              case 9:
                item.assets = {
                  type: 0,
                };
                break;
              case 11:
                item.assets = {
                  type: 0,
                };
                break;
              case 12:
                item.assets = {
                  type: 0,
                };
                break;
              case 14:
                if (
                  Number(item.amount) <=
                  Number(this.sets.withdraw_brc_fee_min_number)
                ) {
                  item.amount14 = 0;
                }
                if (
                  Number(item.amount) >
                  Number(this.sets.withdraw_brc_fee_min_number)
                ) {
                  if (
                    Number(this.sets.withdraw_brc_fee_min_number) >=
                    Number(item.amount * this.sets.withdraw_brc_fee_ratio)
                  ) {
                    item.amount14 =
                      item.amount - this.sets.withdraw_brc_fee_min_number;
                  } else {
                    item.amount14 = (
                      item.amount *
                      (1 - this.sets.withdraw_brc_fee_ratio)
                    ).toFixed(2);
                  }
                }
                item.assets = {
                  type: 0,
                };
                break;
              case 15:
                if (
                  Number(item.amount) <=
                  Number(this.sets.withdraw_brc_fee_min_number)
                ) {
                  item.amount15 = 0;
                }
                if (
                  Number(item.amount) >
                  Number(this.sets.withdraw_brc_fee_min_number)
                ) {
                  if (
                    Number(this.sets.withdraw_brc_fee_min_number) >=
                    Number(item.amount * this.sets.withdraw_brc_fee_ratio)
                  ) {
                    item.amount15 =
                      item.amount - this.sets.withdraw_brc_fee_min_number;
                  } else {
                    item.amount15 = (
                      item.amount *
                      (1 - this.sets.withdraw_brc_fee_ratio)
                    ).toFixed(2);
                  }
                }
                item.assets = {
                  type: 0,
                };
                break;
              case 16:
                item.assets = {
                  type: 0,
                };
                break;
              case 17:
                if (
                  Number(item.amount) <=
                  Number(this.sets.withdraw_brc_fee_min_number)
                ) {
                  item.amount17 = 0;
                }
                if (
                  Number(item.amount) >
                  Number(this.sets.withdraw_brc_fee_min_number)
                ) {
                  if (
                    Number(this.sets.withdraw_brc_fee_min_number) >=
                    Number(item.amount * this.sets.withdraw_brc_fee_ratio)
                  ) {
                    item.amount17 =
                      item.amount - this.sets.withdraw_brc_fee_min_number;
                  } else {
                    item.amount17 = (
                      item.amount *
                      (1 - this.sets.withdraw_brc_fee_ratio)
                    ).toFixed(2);
                  }
                }
                item.assets = {
                  type: 0,
                };
                break;
              case 19:
              case 20:
              case 21:
              case 22:
              case 23:
              case 30:
              case 31:
              case 32:
              case 33:
              case 34:
              case 35:
              case 36:
                item.amount = Number(item.amount);
                break;
              case 50:
                item.assets = {
                  type: 0,
                };
                break;
              case 51:
                if (
                  Number(item.amount) <=
                  Number(this.sets.bgg_withdraw_min_fee)
                ) {
                  item.amount51 = 0;
                }
                if (
                  Number(item.amount) >
                  Number(this.sets.bgg_withdraw_min_fee)
                ) {
                  if (
                    Number(this.sets.bgg_withdraw_min_fee) >=
                    Number(item.amount * this.sets.bgg_withdraw_fee_ratio)
                  ) {
                    item.amount51 =
                      item.amount - this.sets.bgg_withdraw_min_fee;
                  } else {
                    item.amount51 = (
                      item.amount *
                      (1 - this.sets.bgg_withdraw_fee_ratio)
                    ).toFixed(2);
                  }
                }
                item.assets = {
                  type: 0,
                };
                break;
              case 53:
                if (
                  Number(item.amount) <=
                  Number(this.sets.bgg_withdraw_min_fee)
                ) {
                  item.amount53 = 0;
                }
                if (
                  Number(item.amount) >
                  Number(this.sets.bgg_withdraw_min_fee)
                ) {
                  if (
                    Number(this.sets.bgg_withdraw_min_fee) >=
                    Number(item.amount * this.sets.bgg_withdraw_fee_ratio)
                  ) {
                    item.amount53 =
                      item.amount - this.sets.bgg_withdraw_min_fee;
                  } else {
                    item.amount53 = (
                      item.amount *
                      (1 - this.sets.bgg_withdraw_fee_ratio)
                    ).toFixed(2);
                  }
                }
                item.assets = {
                  type: 0,
                };
                break;
              case 54:
                if (
                  Number(item.amount) <=
                  Number(this.sets.bgg_withdraw_min_fee)
                ) {
                  item.amount54 = 0;
                }
                if (
                  Number(item.amount) >
                  Number(this.sets.bgg_withdraw_min_fee)
                ) {
                  if (
                    Number(this.sets.bgg_withdraw_min_fee) >=
                    Number(item.amount * this.sets.bgg_withdraw_fee_ratio)
                  ) {
                    item.amount54 =
                      item.amount - this.sets.bgg_withdraw_min_fee;
                  } else {
                    item.amount54 = (
                      item.amount *
                      (1 - this.sets.bgg_withdraw_fee_ratio)
                    ).toFixed(2);
                  }
                }
                item.assets = {
                  type: 0,
                };
                break;
              case 52:
                item.assets = {
                  type: 0,
                };
                break;
              case 55:
                item.assets = {
                  type: 0,
                };
                break;
              case 60:
                item.assets = {
                  type: 0,
                };
                break;
              default:
                break;
            }
          });
          this.activityList = this.activityList.concat(res.data.list);
          this.meta = {
            current: Number(res.data.page),
            last: res.data.totalPage || 1,
            total: res.data.total,
          };
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>


<style lang="scss" scoped>
.section {
  width: 100%;
  min-height: 500px;
  background: rgba(41, 43, 57, 0.5);
  border: 1px solid #4c5158;
  border-radius: 4px;
  .timeline-account {
    padding: 20px;
    .box {
      p {
        color: #fff;
        margin-bottom: 5px;
      }
      a {
        color: #999;
        text-overflow: ellipsis;
        display: -webkit-box;
        word-break: break-all;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-decoration: underline;
      }
    }
  }
}
.more {
  align-self: flex-end;
}
.border_b {
  border-bottom: 1px solid #464853;
}
</style>